import React from "react";

import QrNavbar from "../components/QrNavbar";
import QrFooter from "../components/QrFooter";
import { QrSEO } from "../components/QrHead";
import Banner from "../components/Banner";
import About from "./home/about";
import Solutions from "./home/solutions";
import Clients from "./home/clients";
//import Press from "./home/press";
import Contact from "./home/contact";

const IndexPage = () => {
  return (
    <div id="qr-wrap">
      <QrSEO pageName="Home" pageClass="IndexPage" />
      <QrNavbar type="transparent" home />

      {/* 1 - Início */}
      <Banner />

      {/* 2 - Breve Histórico */}
      <About />

      {/* 3 - Soluções */}
      <Solutions />

      {/* 4 - Clientes */}
      <Clients />

      {/* 5 - Imprensa */}
      {/* <Press /> */}

      {/* 6 - Contato */}
      <Contact />

      <QrFooter />
    </div>
  )
}

export default IndexPage
